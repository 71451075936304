import { ApiClient } from 'jsonapi-react'
import { GraphitiPlugin } from 'jsonapi-react-plugin-graphiti'
import env from '../env'

import agreements from './agreements/schema'
import booking_packages from './booking-packages/schema'
import booking_package_items from './booking-package-items/schema'
import booking_package_categories from './booking-package-categories/schema'
import bookings from './bookings/schema'
import calendars from './calendars/schema'
import calendar_events from './calendar-events/schema'
import caterer_agreements from './caterer-agreements/schema'
import caterer_services from './caterer-services/schema'
import caterer_settings from './caterer-settings/schema'
import caterer_stories from './caterer-stories/schema'
import story_tags from './story-tags/schema'
import caterers from './caterers/schema'
import contacts from './contacts/schema'
import documents from './documents/schema'
import events from './events/schema'
import fees from './fees/schema'
import leads from './leads/schema'
import memberships from './memberships/schema'
import menu_categories from './menu-categories/schema'
import menu_items from './menu-items/schema'
import messages from './messages/schema'
import packages from './packages/schema'
import package_items from './package-items/schema'
import package_categories from './package-categories/schema'
import photos from './photos/schema'
import proposals from './proposals/schema'
import promotions from './promotions/schema'
import promotion_accounts from './promotion_accounts/schema'
import users from './users/schema'
import overages from './overages/schema'

export default function createClient(options) {
  return new ApiClient({
    ...options,
    cacheTime: 60 * 10,
    plugins: [
      new GraphitiPlugin(),
    ],
    schema: {
      agreements,
      booking_packages,
      booking_package_items,
      booking_package_categories,
      bookings,
      calendars,
      calendar_events,
      caterer_agreements,
      caterer_services,
      caterer_settings,
      caterer_stories,
      caterers,
      contacts,
      documents,
      events,
      fees,
      leads,
      memberships,
      menu_categories,
      menu_items,
      messages,
      packages,
      package_items,
      package_categories,
      photos,
      proposals,
      promotions,
      promotion_accounts,
      story_tags,
      users,
      overages,
    }
  })
}
