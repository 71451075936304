import React, { useContext } from 'react'
import Header from './HeaderLoggedOut'
import Footer from './Footer'
import Main from './Main'
import HeaderBanner from 'app/theme/HeaderBanner'
import env from 'env'

const helpscoutScript = [
  '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
]

const helpscoutBeacon = [
  "window.Beacon('init', '4ae0db4a-2514-4927-839e-e69a69097208')"
]

export default function Layout(props) {
  return (
    <div className={[props.containerClassName, 'min-h-screen flex flex-col']}>
      {env.NEW_SITE_HEADER_ENABLED && (
        <HeaderBanner message={`
          Exciting Update! Your Vendor Dashboard is moving to a new site where we'll 
          add tools and content to run your business. The move will happen on ${env.NEW_SITE_LAUNCH_DATE}.
        `} />
      )}
      <Header />
      <Main className={props.className}>
        {props.children}
      </Main>
      <Footer />
      {env.APP_ENV == 'production' && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: helpscoutScript
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: helpscoutBeacon
            }}
          />
        </>
      )}
    </div>
  )
}
