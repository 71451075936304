import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, X } from 'react-feather'
import { useSession } from 'app/session'
import logo from 'assets/img/logo.png'
import styles from './Header.module.css'

export default function Header() {
  const session = useSession()
  const [isOpen, setOpen] = useState(false)

  return (
    <header className="sticky top-0 bg-blue shadow z-10">
      <div className={[
        styles.nav,
        'container flex justify-center items-center md:justify-between'
      ]}>
        <Link to="/" title="Roaming Hunger" className="md:mr-10">
          <img src={logo} width={98} />
        </Link>

        <div className={['hidden h-full md:flex text-lg']}>
          <Link
            to="/get-started"
            children="Get Started"
            className={styles.navLink}
          />
          <Link
            to="/login"
            children="Login"
            className={styles.navLink}
          />
        </div>

        <button
          type="button"
          className={[
            'p-2 absolute right-0 mr-3 rounded-md focus:outline-none',
            'hover:bg-blue-600 focus:bg-blue-600 md:hidden'
          ]}
          onClick={() => {
            setOpen(open => !open)
          }}
          children={
            isOpen ? (
              <X className="text-white" size={26} />
            ) : (
              <Menu className="text-white" size={26} />
            )
          }
        />

      </div>

      {isOpen && (
        <div className="container py-4 text-white text-lg border-t border-blue-600 font-light">
          <Link to="/get-started" className="block py-1 my-1">
            Get Started
          </Link>
          <Link to="/login" className="block py-1 my-1">
            Login
          </Link>
        </div>
      )}
    </header>
  )
}
