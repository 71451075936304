import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink as ActiveLink } from 'react-router-dom'
import { ChevronDown, Menu, X } from 'react-feather'
import { useSession } from 'app/session'
import { useFeatureFlagEnabled} from 'lib/posthog'
import logo from 'assets/img/logo.png'
import styles from './Header.module.css'

export default function Header() {
  const session = useSession()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.open)
    }
    return () => {
      if (isOpen) {
        document.body.classList.remove(styles.open)
      }
    }
  }, [isOpen])

  return (
    <header className={[
      styles.header,
      'sticky md:relative z-20 flex flex-col w-full top-0 bg-blue shadow font-national',
    ]}>
      <div className={[
        styles.nav,
        'container flex items-center justify-center md:justify-between'
      ]}>
        <Link to="/" className="md:mr-8 lg:mr-10">
          <img src={logo} width={98} />
        </Link>

        <div className="hidden md:flex flex-1 h-full items-center justify-between">
          <div className="flex h-full lg:text-lg">
            <NavLink
              to="/catering"
              children="Catering"
              className={styles.navLink}
            />
            <NavLink
              to="/public-events"
              children="Vending"
              className={styles.navLink}
            />
            <NavLink
              to="/calendar"
              children="My Calendar"
              className={styles.navLink}
            />
            {/*
            <NavLink
              to="/promotions"
              children="Promotions"
              className={styles.navLink}
            />*/}
          </div>
          <DropDown session={session} />
        </div>

        <button
          type="button"
          className={[
            'p-2 absolute right-0 mr-3 rounded-md focus:outline-none',
            'hover:bg-blue-600 focus:bg-blue-600 md:hidden'
          ]}
          onClick={() => {
            setOpen(open => !open)
          }}
          children={
            isOpen ? (
              <X className="text-white" size={26} />
            ) : (
              <Menu className="text-white" size={26} />
            )
          }
        />
      </div>

      <div className={[styles.menu, 'flex-1 font-light overflow-auto']}>
        <div className="container py-3 border-t border-blue-600">  
          <NavLink to="/account" children="My Account"/>
        </div>
        <div className="container py-3 border-t border-blue-600">
          <NavLink to="/catering" children="Catering" />
          <NavLink to="/public-events" children="Vending" />
          <NavLink to="/calendar" children="My Calendar" />
          {/* <NavLink to="/promotions" children="Promotions" /> */}
        </div>
        <div className="container py-3 border-t border-blue-600">
          <SettingsLinks />
        </div>
        {session.user.caterers.length > 0 && (
          <div className="container border-t border-blue-600 py-3">
            {session.user.caterers.map(caterer => (
              <Link
                key={caterer.id}
                to="#"
                children={caterer.name}
                className={session.caterer.id === caterer.id ? styles.active : undefined}
                onClick={() => {
                  session.setCaterer(caterer)
                  setOpen(false)
                }}
              />
            ))}
          </div>
        )}
        <div className="container border-t border-blue-600 py-3">
          <Link to="/logout" children="Log Out" />
        </div>
      </div>

    </header>
  )
}

function NavLink(props) {
  return <ActiveLink {...props} activeClassName={styles.active} />
}

function SettingsLinks() {
  const tipaltiPayments = useFeatureFlagEnabled('tipalti_payments')
  return (
    <>
      <NavLink to="/settings/profile" children="Caterer Profile" />
      <NavLink to="/settings/services" children="Services" />
      <NavLink to="/settings/menu" children="Menu" />
      <NavLink to="/settings/packages" children="Packages" />
      <NavLink to="/settings/members" children="Members" />
      <NavLink to="/settings/documents" children="Terms & Documents" />
      <NavLink to="/settings/payments" children="Payments" />
      {tipaltiPayments && (
        <NavLink to="/settings/payment-details" children="Payment Details" />
      )}
    </>
  )
}

function DropDown({ session }) {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const onOutsideClick = ({ target }) => {
      if (
        target.tagName === 'A' ||
        ref.current &&
        ref.current !== target &&
        !ref.current.contains(target)
      ) {
        setOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('click', onOutsideClick)
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('click', onOutsideClick)
      }
    }
  }, [isOpen])

  return (
    <div className="relative h-full">
      <button
        type="button"
        onClick={() => setOpen(open => !open) }
        className={[
          'flex items-center text-white font-semibold',
          'h-full lg:text-lg focus:outline-none',
        ]}
      >
        {session.caterer.logo && (
          <div
            className={[
              'hidden lg:block',
              'mr-3 bg-contain bg-no-repeat bg-center'
            ]}
            style={{
              width: 40,
              height: 40,
              backgroundImage: `url(${session.caterer.logo.url})`,
            }}
          />
        )}
        <span>{session.caterer.name}</span>
        <ChevronDown
          size={20}
          className={[
            'ml-1 mt-1 transform transition-all duration-75',
            isOpen && 'rotate-180',
          ]}
        />
      </button>
      <div ref={ref} className={[styles.dropDown, isOpen && styles.open]}>
        <div className="py-4 px-6 leading-tight font-normal text-xl">
          <div className="truncate">
            {[
              session.user.first_name,
              session.user.last_name,
            ].filter(Boolean).join(' ')}
          </div>
          <div className="text-sm text-gray-700 truncate font-light">
            {session.user.email}
          </div>
        </div>
        <div className="border-t border-gray-200 py-2">
          <Link to="/account">
            My Account
          </Link>
        </div>
        <div className="border-t border-gray-200 py-2">
          <SettingsLinks />
        </div>
        {session.user.caterers.length > 0 && (
          <div className="border-t border-gray-200 py-2">
            {session.user.caterers.map(caterer => (
              <Link
                key={caterer.id}
                to="#"
                children={caterer.name}
                onClick={() => {
                  session.setCaterer(caterer)
                }}
              />
            ))}
          </div>
        )}
        <div className="border-t border-gray-200 py-2">
          <Link to="/logout">Sign Out</Link>
        </div>
      </div>
    </div>
  )
}
