import React from 'react'
import loadable, { lazy } from '@loadable/component'
import { Redirect } from 'react-router-dom'
import { Layout } from './theme/layout'

const ungated = [
  {
    path: '/',
    component: loadable(() => import('./pages/HomePage')),
  },
  {
    path: '/get-started',
    component: loadable(() => import('./pages/GetStartedPage')),
  },
  {
    path: '/login',
    component: loadable(() => import('./pages/LoginPage')),
  },
  {
    path: '/logout',
    component: loadable(() => import('./pages/LogoutPage')),
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('./pages/ForgotLoginPage')),
  },
  {
    path: '/reset-password',
    component: loadable(() => import('./pages/ResetPasswordPage')),
  },
  {
    path: '*',
    component: () => <Redirect to="/" />,
  }
]

const gated = [
  {
    path: '/catering/:bookingId(\\d+)?',
    component: () => import('./pages/CateringPage'),
  },
  {
    path: '/public-events/:bookingId(\\d+)?',
    component: () => import('./pages/VendingPage'),
  },
  {
    path: '/calendar',
    component: () => import('./pages/CalendarPage'),
  },
  /* {
    path: '/promotions',
    component: () => import('./pages/PromotionsPage'),
  }, 
  {
    path: '/promotions/:promotionCode/:step?',
    component: () => import('./pages/PromotionPage'),
  },*/
  {
    path: '/settings/*',
    component: () => import('./pages/SettingsPage'),
  },
  {
    path: ['/account', '/account/*'],
    component: () => import('./pages/AccountPage'),
  },
  {
    path: '/google/callback',
    component: () => import('./pages/calendar/GoogleCallback'),
  },
].map(route => ({
  ...route,
  component: loadable(route.component, {
    fallback: <Layout isLoading />,
  })
}))

export default {
  gated,
  ungated,
}
